.pool-main {
    
    box-sizing: border-box;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    width: 100%;
    /* padding: 10px 0 0; */
    position: relative;
    min-height: calc(100vh - 255px);
    background-color: #131313;
    overflow: auto;

}

.aido {
display: block;
min-width: auto;
margin: 0 25px;
}

.accordion {
    /* max-width: 600px; */
    margin: 2rem auto;
    border: 0.5px solid rgb(128,128,128,0.2);
    border-radius: 23px;
    background-color: #1d1d1d;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    
    color: #fff;
  }
  
  .accordion-title:hover {
    background-color: transparent;
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background-color: transparent;
    color: #fff;
  }

  .accordion-item{
      margin-bottom: 25px;
      
  }


  .main{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 15px;
    align-items: flex-end;
  }

  @media (min-width: 960px){

    .token_info{
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .wallet_info{
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
    }
  }
  
  

  .jssp189{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  
  

  .jssp310 {
    width: 48px;
    height: 48px;
    display: flex;
    position: relative;
    flex-grow: 0;
    align-items: center;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
}
  
  .jssp190{
    width: 60px;
    cursor: pointer;
    height: 60px;
    margin-right: 8px;
    /* transform: rotate(45deg); */

  }

  


.jcssp311 {
    max-width: auto;
    /* max-height: 100%; */
    height: 100%;
    object-fit: contain;    
}

.jssp310 .jcssp311 {
    width: 81.6667%;
    height: 81.6667%;
    position: absolute;
}
.jssp310 .jcssp311:first-child {
    left: 0;
    z-index: 2;
}

/* .jssp310 .jcssp311:last-child {
    right: -12px;
    z-index: 1;
} */

.jssp202{
    /* margin: 0 20px; */
    display: block;

}



.jssp213{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.jssp204{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.9rem;
}

.jssp204 div{
margin-top: 15px;
}

.main001{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 44px;
    justify-content: space-around;
    align-items: flex-end;
}

.jssp205{
  border: 0.5px solid rgb(128,128,128,0.2);
  border-radius: 23px;
  padding: 2vw;
  height: auto;
  flex-basis: 28%;
  margin: auto;

} 

.jssp2052{
  /* border: 0.5px solid rgb(128,128,128,0.2); */
  padding: 2px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    line-height: 42px;
    flex-basis: 18%;
    justify-content: center;
}


.pool_claim{
  height: auto;
    /* line-height: 52px; */
    border-radius: 9px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    /* background-image: linear-gradient(180deg , #10a3d7 , #2669f5); */
    /* background-image: linear-gradient(180deg , #00e2c8, #00e2c8); */
    background-color: #03c3ec;
    width: 48%;
    padding: 10px 6px;
}

.claim_box{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.jssp214{
  display: flex;
}

@media (max-width: 1000px) {
  .jssp214 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .jssp213{
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .jssp204{
    margin: 3.8rem 1.4rem 0;
  }
  .farm-claim, .fmsize, .pool_claim{
    font-size: 13px !important;
  }
  .ml{
    
    margin-left: -10px;

  }
}

@media (max-width: 389px) {
  .jssp204{
    margin: 3.8rem 0.9rem 0;
  }  
}

.ct1-inputpool input {
  width: 100%;
  height: auto;
  background: transparent;
  border-radius: 10px;
  border: none;
  padding-left: 10px;
  color: #ddd;
  border: 1px solid rgb(128,128,128,0.9);
  padding: 10px 16px;
}

.borderpool { 
  border-bottom: 0.2px solid #e7e7e7;
  border-radius: 8px;
  opacity: 0.5;
}
.width100pool{
  width: 100%;
}


.lkdpara{
  display: flex;
  justify-content: space-between;
}

@media (max-width: 960px) {
  .jssp205 {
    flex-basis: 100%;
    /* margin-top: 25px; */
    padding: 15px;
    width: -webkit-fill-available;
  }
  
  .jssp2052 {
    flex-basis: 100%;
    /* margin-top: 25px; */
    justify-content: center;
    text-align: center;
    width: -webkit-fill-available;
  }

  .margintop25{
    margin-top: 25px;
  }
  .width100pool{
    width: 17rem;
  }
}

.text_grey{
  color: #999999;
  cursor: pointer;
}

.v_align1{
  vertical-align: -1px;
}

.pool_approve{
  height: auto;
    /* line-height: 52px; */
    border-radius: 9px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    /* background-image: linear-gradient(180deg , #10a3d7 , #2669f5); */
    background-color: #03c3ec;
    width: 46%;
    padding: 10px 16px;
}

.back_grey{
  background-image: linear-gradient(180deg , #7a7770, #7a7770);
}

.fsmall{
  font-size: small;
}

.claimdiv{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.text_center{
  text-align: center;
}


@media (min-width:1001px) {
  .content_1000{
    display: none;
  }
  
}

@media (max-width:1000px) {
  .content_1001{
    display: none;
  }
 
}